export const environment = {
  mode: 'production',
  version: '1.2.10',
  coreVersion: '4.17',
  production: true,
  bundleId: 'com.namsoftware.probox',
  name: 'ProBox.vn',
  title: 'Cổng thông tin của:<br>Công Ty Cổ Phần Công Nghệ ProBox<br>Mã Số Doanh Nghiệp: 0314341120',
  copyright: 'Copyright ProBox năm 2017',
  api: {
    defaultUrl: 'https://appsv1.probox.vn/v3',
    version: 'v4'
  },
  universal: {
    link: 'https://probox.vn',
  },
  authentication: {
    notLoggedInUrl: '/login',
  },
  notification: {
    senderId: 68259054609,
  },
  tokenStorageKey: 'jwt-token',
  deployment: {
    ios: {
      baseBuild: 159,
      key: 'mMsrlN3KtTe_R7muxP85g7icUdz6PXMZpUuip',
    },
    android: {
      baseBuild: 157,
      key: 'SUw17BStC5hWZoI44mtELQ56p7ju--TtBzuqn',
    },
  },
  google: {
    maps: {
      apiKey: 'AIzaSyDjQl1YsJdrESCyYpxIXTFs7oTiL1nxKY8',
    },
  },
  chat: {
    requireLocation: false
  }
};
