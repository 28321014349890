import { take } from 'rxjs/operators';
import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { CollaboratorOrderModel, CollaboratorOrderDetailModel } from 'src/app/model/collaborator.model';
import { RootServices } from 'src/app/services/root.services';
import { F7ComponentContext, F7Page } from 'src/app/types/framework7-types';
import { CollaboratorStateMap } from 'vendors/smart-bot-app/src/app/components/collaborator/collaborator.state.map';
import { FormComponentStateExtend, BaseFormComponent, FormSchema } from 'vendors/smart-bot-app/src/app/lib/base-form-component';
import { ChatRoomModel } from 'vendors/smart-bot-app/src/app/model/chat-room.model';
import { ContactModel } from 'vendors/smart-bot-app/src/app/model/contact.model';
import { FileModel } from 'vendors/smart-bot-app/src/app/model/file.model';
import { UnitModel, ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { SalesMasterPriceTable } from 'vendors/smart-bot-app/src/app/model/sales.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<CollaboratorOrderModel> {
    [key: string]: any;
    instance: F7ComponentContextExtend,
    data?: CollaboratorOrderModel;
    form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
    responseInProgress?: boolean;

    detailDialog?: Dialog.Dialog;

    $root: any;
    $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class PageCollaboratorReportPublisherCommissionFormComponent extends BaseFormComponent<ComponentStateExtend, CollaboratorOrderModel> {
    // states: { [key: string]: State } = {};
    currentState: ComponentStateExtend;
    title = 'Báo cáo CK CTV';

    idKey = 'Code';
    apiPath = '/collaborator/report/publisher/commissions';

    unitList: UnitModel[] = [];
    pageList: UnitModel[] = [];
    priceTableList: SalesMasterPriceTable[] = [];

    contactList: ContactModel[] = [];

    schema: FormSchema = {
        ObjectName: {
            type: 'input',
            label: 'Tên nhà cung cấp',
            validators: [
                'required'
            ]
        },
        Object: {
            type: 'autocomplete',
            autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
            ajax: (query: any) => {
                return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
                    return results.map(m => {
                        m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
                        return m;
                    });
                });
            },
            label: 'Khách hàng',
            validators: [
                'required'
            ]
        },
        Page: {
            type: 'smart-select',
            label: 'Trang',
            validators: [
                'required'
            ]
        },
        Title: {
            type: 'input',
            label: 'Tiêu đề',
            validators: [
                'required'
            ]
        },
    };

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
    ) {
        super(rootServices, commonService);
        // // console.log('Click here to open ts file');

        this.rootServices.platform.pause.subscribe(status => {
            // Auto save
            // this.currentState?.data?.Code && this.currentState?.data?.State != 'APPROVED' && this.currentState.instance.save();
        });
    }

    makeModel(properties?: CollaboratorOrderModel): CollaboratorOrderModel {

        // if (!properties) {
        //   properties = {};
        // }
        // if (!properties.Page) {
        //   properties.Page = this.pageList[0];
        // }
        return new CollaboratorOrderModel(properties);
    }

    async makeNewData() {
        return new CollaboratorOrderModel();
    }

    getFormData(params?: any): Promise<CollaboratorOrderModel> {
        if (!params) {
            params = {};
        }
        params['page'] = this.currentState.instance.$route?.context['pageInfo']['Code'];
        return super.getFormData({
            // includeObject: true,
            // includeContact: true,
            includeDetails: true,
            // includeRelativeVouchers: true,
            ...params,
        }).then(rs => {
            // if (rs.RelativeVouchers) {
            //     rs.RelativeVouchers.map(relativeVoucher => {
            //         relativeVoucher.type = this.commonService.voucherTypeMap[relativeVoucher.type] || { id: relativeVoucher.type };
            //     });
            // }
            return rs;
        });
    }

    prepareData(data: CollaboratorOrderModel): CollaboratorOrderModel {
        for (const detail of data.Details) {

            // if (detail?.Unit && typeof detail?.Unit == 'string') {
            //     detail.Unit = this.unitList.find(f => f.id == detail.Unit as any);
            // }

            // if (!data.Pictures) {
            //     data.Pictures = [];
            // }
        };


        if (data.RelativeVouchers) {
            for (const relativeVoucher of data.RelativeVouchers) {
                if (relativeVoucher.state) {
                    relativeVoucher.state = this.commonService.stateMap[relativeVoucher.state];
                }
            }
        }

        // data = {
        //     ...data,
        //     ...data.ReportData,
        // };

        for (const key in data.ReportData) {
            if (['ReportData', 'Id', 'Code', 'SystemUuid', 'State'].indexOf(key) < 0) {
                data[key] = data.ReportData[key];
            }
        }

        data.DetailsOfReportByProduct = [];
        data.DetailsOfReportByVoucher = [];
        data.DetailsOfMainReportByVoucher = [];
        data.ReportPart = [];
        const reportPartsMap = {};

        for (let detail of data.Details) {
            // detail = {
            //     ...detail,
            //     ...detail.ReportData,
            // };

            for (const key in detail.ReportData) {
                if (['ReportData', 'Id', 'Code', 'SystemUuid', 'State'].indexOf(key) < 0) {
                    detail[key] = detail.ReportData[key];
                }
            }

            if (detail.Type == 'REPORTBYVOUCHER') {
                detail.GeneratePaidRatio = 0;
                detail.GeneratePaidAmount = detail.GeneratePaidAmount || 0;
                if (detail.GenerateCredit) {
                    detail.GeneratePaidRatio = (detail.GeneratePaidAmount / detail.GenerateCredit * 100).toFixed(0);
                }
                // data.DetailsOfReportByVoucher.push(detail);
                // if (detail.ReportPart == data.MainReportPart) {
                //     data.DetailsOfMainReportByVoucher.push(detail);
                // } else {
                //     if (!data.ReportPart[detail.ReportPart]) {
                //         data.ReportPart[detail.ReportPart] = {
                //             ReportPart: { id: detail.REportPart, text: detail.ReportPartLabel },
                //             Details: [],
                //         };
                //     }
                //     data.ReportPart[detail.ReportPart].Details.push(detail);
                // }

                if (detail['ReportPart']) {
                    if (!reportPartsMap[detail['ReportPart']]) {
                        reportPartsMap[detail['ReportPart']] = {
                            ReportPart: detail['ReportPart'],
                            ReportPartLabel: detail['ReportPartLabel'],
                            GenerateCredit: 0,
                            GeneratePaidAmount: 0,
                            BasicCommissionAmount: 0,
                            AdvanceCommissionAmount: 0,
                            AddonCommissionAmount: 0,
                            TotalCommissionAmount: 0,
                            RealTotalCommissionAmount: 0,
                            Details: []
                        };
                    }
                    reportPartsMap[detail['ReportPart']]['GenerateCredit'] += parseFloat(detail.GenerateCredit || 0);
                    reportPartsMap[detail['ReportPart']]['GeneratePaidAmount'] += parseFloat(detail.GeneratePaidAmount || 0);
                    reportPartsMap[detail['ReportPart']]['BasicCommissionAmount'] += parseFloat(detail.BasicCommissionAmount || 0);
                    reportPartsMap[detail['ReportPart']]['AdvanceCommissionAmount'] += parseFloat(detail.AdvanceCommissionAmount || 0);
                    reportPartsMap[detail['ReportPart']]['AddonCommissionAmount'] += parseFloat(detail.AddonCommissionAmount || 0);
                    reportPartsMap[detail['ReportPart']]['TotalCommissionAmount'] += parseFloat(detail.TotalCommissionAmount || 0);
                    reportPartsMap[detail['ReportPart']]['RealTotalCommissionAmount'] += parseFloat(detail.RealTotalCommissionAmount || 0);
                    reportPartsMap[detail['ReportPart']]['Details'].push(detail);
                }
            }

            if (detail.Type == 'REPORTBYPRODUCT') {
                detail.GeneratePaidRatio = 0;
                detail.GeneratePaidAmount = detail.GeneratePaidAmount || 0;
                if (detail.GenerateCredit) {
                    detail.GeneratePaidRatio = (detail.GeneratePaidAmount / detail.GenerateCredit * 100).toFixed(0);
                }
                data.DetailsOfReportByProduct.push(detail);
            }
        }



        let parts = (Object.keys(reportPartsMap).sort()).reverse();
        data.PartDetails = [];
        for (const part of parts) {
            data.PartDetails.push(reportPartsMap[part]);
        }

        data.MainSummary = data.Summary['REPORTBYVOUCHER'][data.MainReportPart];
        data.MainSummaryForProduct = data.Summary['REPORTBYPRODUCT'][data.MainReportPart];
        data.HeadSummary = {
            GenerateCredit: 0,
            GeneratePaidAmount: 0,
            BasicCommissionAmount: 0,
            AdvanceCommissionAmount: 0,
            AddonCommissionAmount: 0,
            TotalCommissionAmount: 0,
            RealTotalCommissionAmount: 0,
        };
        for (const part in data.Summary['REPORTBYVOUCHER']) {
            if (part != data.MainReportPart) {
                const summary = data.Summary['REPORTBYVOUCHER'][part];
                data.HeadSummary.GenerateCredit += parseFloat(summary.GenerateCredit || 0);
                data.HeadSummary.GeneratePaidAmount += parseFloat(summary.GeneratePaidAmount || 0);
                data.HeadSummary.BasicCommissionAmount += parseFloat(summary.BasicCommissionAmount || 0);
                data.HeadSummary.AdvanceCommissionAmount += parseFloat(summary.AdvanceCommissionAmount || 0);
                data.HeadSummary.AddonCommissionAmount += parseFloat(summary.AddonCommissionAmount || 0);
                data.HeadSummary.RealTotalCommissionAmount += parseFloat(summary.RealTotalCommissionAmount || 0);
            }
        }
        data.RealTotalCommissionAmount = parseFloat(data.MainSummary.RealTotalCommissionAmount || 0) + parseFloat(data.HeadSummary.RealTotalCommissionAmount || 0);
        return data;
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
        const $this = this;

        // Start loading status
        state.instance.$setState({
            loading: true,
            schema: this.schema,
        });
        this.commonService.showPreloader();

        // Load Contact Member list
        // if (state?.instance?.$route?.context?.chatRoom?.memberList$) {
        //     await state?.instance?.$route?.context?.chatRoom?.memberList$?.pipe(take(1)).toPromise().then(memberList => {
        //         state.instance.contactList = memberList.filter(f => f.Type == 'CONTACT').map(m => { m['text'] = m.Name; return m; }) as any;
        //         if (state.instance.contactList && state.instance.contactList.length > 0) {
        //             this.schema['Object'].validators = [];
        //         } else {
        //             // this.schema['Object'].validators = [];
        //         }
        //     });

        // }

        // Get static data
        // await this.rootServices.apiService.getPromise<UnitModel[]>('/admin-product/units', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
        //     state.instance.unitList = rs;
        // });
        await this.rootServices.apiService.getPromise<any[]>('/collaborator/subscription-pages', { includeIdText: true, limit: 'nolimit' }).then(rs => {
            state.instance.pageList = rs.map(m => {m.id = m.Page; m.text = m.Name; return m;});
        });
        // End
        state.instance.$setState({
            // unitList: state.instance.unitList,
            // contactList: state.instance.contactList,
            processMap: CollaboratorStateMap.publisherCommissionStateMap,
        });

        const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;


        if (currentState.instance.$route?.context?.copyFromId) {
            currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>' + currentState.instance.data.SubNote) : '');
            this.setData(currentState.instance.data);
        }

        if (currentState?.instance?.$route?.context?.relativeVouchers && currentState.instance.$route.context.relativeVouchers[0]?.text) {
            if (!currentState.data.Title) {
                currentState.data.Title = currentState.instance.$route.context.relativeVouchers[0]?.text;
                currentState.instance.$setState({ data: currentState.data });
                this.validate();
            }
        }

        // set static data
        if (!currentState.data?.Page) {
            currentState.data.Page = currentState.instance.pageList[0];
            // currentState.data.Title = 'Debug';
            this.setData(currentState.instance.data);
        }

        // Prepare data
        // if (currentState.data) {
        //   // for (const detail of currentState.data?.Details) {
        //   //   detail.__unitList = detail.Product?.Units || [];
        //   //   detail.ToMoney = detail.Price * detail.Quantity;
        //   // }
        //   // currentState.data.Object2 = currentState.data.Object;
        //   // currentState.data.Contact2 = currentState.data.Contact;
        //   await this.setData(currentState.data, { prepareControl: true });
        //   // this.calculateTotal();
        // }
        // End Prepare data

        // Stop loading status
        currentState.instance.$setState({ loading: false });
        this.commonService.hidePreloader();
        return currentState;
    }

    async setData(data: CollaboratorOrderModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {
        if (data.State) {
            data.State = this.commonService.getObjectId(data.State);
        }
        // if (data?.Details) {
        //     const productIds = data.Details.filter(f => this.commonService.getObjectId(f.Type) != 'CATEGORY').map(m => `${this.commonService.getObjectId(this.currentState.data['Page'])}-${this.commonService.getObjectId(m.Product)}`);
        //     const productInfoMap: { [key: string]: ProductModel } = await this.rootServices.apiService.getPromise<ProductModel[]>('/collaborator/page-products', {
        //         id: productIds,
        //         includeIdText: true,
        //         includeProduct: true,
        //         includeUnit: true,
        //         includeUnitPrices: true,
        //         productOfPage: true,
        //     }).then(rs => rs.reduce((prev, next, i) => {
        //         prev[this.commonService.getObjectId(next)] = next;
        //         return prev;
        //     }, {}));

        //     // const unitPriceMap = await this.rootServices.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-table-details', {
        //     //   priceTable: 'default',
        //     //   eq_Code: '[' + productIds.join(',') + ']',
        //     // }).then(rs => rs.reduce((result, current, index) => { result[current.Code + '-' + this.commonService.getObjectId(current.Unit)] = current.Price; return result; }, {}));

        //     for (const detail of data.Details) {
        //         if (!detail.__unitList || detail.__unitList.length == 0) {
        //             detail.__unitList = productInfoMap[this.commonService.getObjectId(detail.Product)]?.Units.map(m => {
        //                 // m.Price = unitPriceMap[this.commonService.getObjectId(detail.Product) + '-' + this.commonService.getObjectId(m)];
        //                 return m;
        //             });
        //             // detail.__unitList = detail.Product?.Units || [];
        //         }
        //         // for (const unit of detail.__unitList) {
        //         //   unit.Price = unitPriceMap[this.commonService.getObjectId(detail.Product) + '-' + this.commonService.getObjectId(unit)];
        //         // }
        //         detail.ToMoney = detail.Price * detail.Quantity;
        //     }
        // }

        // data.Object = data.Object;
        // data.Publisher = data.Publisher;

        const total = this.calculateTotal(data);
        return super.setData(data, option).then(status => {
            this.currentState.instance.$setState({ total });

            // Update tabs

            return status;
        });
    }

    async addItemForList<CollaboratorOrderDetailModel>(listName: string, item?: CollaboratorOrderDetailModel): Promise<CollaboratorOrderDetailModel> {
        return super.addItemForList(listName, item).then(newItem => {
            this.commonService.showInfo('Đã thêm sản phẩm', { position: 'bottom' });
            return newItem;
        }).then(rs => {
            this.activeItem(this.currentState.data.Details.length - 1);
            this.rootServices.playNewPipSound();
            return rs;
        });
    }

    activeItem(index: number, newData?: CollaboratorOrderDetailModel) {

        this.currentState.data.Details.map(detail => {
            detail.__active = false;
        });
        this.currentState.data.Details[index].__active = true;

        const total = this.calculateTotal();
        this.currentState.instance.$setState({ data: this.currentState.data, total });
        // if (newData?.AccessNumbers) {
        //   setTimeout(() => {
        //     this.setFieldValue('smart-select', 'AccessNumbers', newData.AccessNumbers, 'Details', index);
        //   }, 300);
        // }
        setTimeout(() => {
            const itemEl = $(this.currentState.instance.el).find('.list-item.index-' + index);
            // itemEl[0] && itemEl[0].scrollIntoView();
            if (itemEl.length > 0) {
                const pageContentEl = $(this.currentState.instance.el).find('.page-content');
                pageContentEl.animate({ scrollTop: pageContentEl.scrollTop() + itemEl.offset().top - itemEl.height() }, 300);
            }
        }, 300);


    }

    async updateImages(state: (progress) => void, onAfterImageUpload?: (image: FileModel) => void) {

        let proloaderProcessing;
        try {
            const localFiles = await this.rootServices.pickFiles();
            console.debug(localFiles);

            this.commonService.showPreloader();
            proloaderProcessing = setTimeout(() => {
                this.commonService.hidePreloader();
            }, 16000);

            let images: FileModel[] = [];
            for (const i in localFiles) {
                // let image: FileModel = null;
                images[i] = await ((progress) => {
                    if (localFiles[i].dataType === 'url') {
                        return this.rootServices.uploadLocalFile(localFiles[i].url as string, (event) => {
                            progress(event)
                        });
                    } else {
                        const formData = new FormData();
                        const imgBlob = new Blob([localFiles[i].url], {
                            type: localFiles[i].type
                        });
                        formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[i].ext);
                        return this.rootServices.uploadFileData(formData, (event) => {
                            progress(event)
                        });
                    }
                })((progress) => {
                    console.log(progress);
                    state(progress);
                });
                onAfterImageUpload && onAfterImageUpload(images[i]);
            }
            console.debug(images);
            clearTimeout(proloaderProcessing);
            this.commonService.hidePreloader();
            return images;
        } catch (err) {
            console.error(err);
            this.commonService.showError(err, { position: 'bottom' }); clearTimeout(proloaderProcessing);
            this.commonService.hidePreloader();
            throw new Error(err);
        }
    }

    async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
        const currentState = this.currentState;
        return super.onFieldChange(self, e, args).then(async fieldInfo => {

            if (!fieldInfo) {
                return null;
            }

            let { field, fieldName, fieldValue, index, listName, previousValue: previousUnit } = fieldInfo;
            let requireSetData = false;

            console.log('on field change', listName, index, fieldName, fieldValue);
            // if (listName == 'Details' && fieldName == 'Quantity') {

            //     currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
            //     // currentState.instance.$setState({ data: currentState.data });
            //     this.rootServices.playDecreasePipSound();
            //     requireSetData = true;
            // }

            // if (listName == 'Details' && fieldName == 'Price') {

            //     currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
            //     currentState.instance.$setState({ data: currentState.data });
            //     // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="ToMoney"]').val(currentState.data[listName][index]['ToMoney']);
            // }
            // if (listName == 'Details' && fieldName == 'ToMoney') {

            //     if (currentState.data[listName][index]['Quantity'] > 0) {
            //         currentState.data[listName][index]['Price'] = (currentState.data[listName][index]['ToMoney'] || 0) / currentState.data[listName][index]['Quantity'];
            //     }
            //     // currentState.instance.$setState({ data: currentState.data });
            //     requireSetData = true;
            //     // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="Price"]').val(currentState.data[listName][index]['Price']);
            // }

            if ((fieldName == 'Object') && fieldValue && !currentState.instance.loading) {
                await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

                    // Auto fill object
                    currentState.data.Object = fieldValue;
                    currentState.data.ObjectName = contact.Name;
                    currentState.data.ObjectPhone = contact.Phone;
                    currentState.data.ObjectEmail = contact.Email;
                    currentState.data.ObjectAddress = contact.Address;

                    // currentState.data.DeliveryProvince = contact.Province;
                    // currentState.data.DeliveryDistrict = contact.District;
                    // currentState.data.DeliveryWard = contact.Ward;
                    // currentState.data.DeliveryAddress = contact.Address;

                    // currentState.instance.$setState({
                    //   data: currentState.data,
                    // });
                    requireSetData = true;
                    this.validate();
                });
            }
            // if ((fieldName == 'DirectReceiver') && fieldValue && !currentState.instance.loading) {
            //     await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

            //         // Auto fill object
            //         currentState.data.DirectReceiver = fieldValue;
            //         currentState.data.DirectReceiverName = contact.Name;
            //         currentState.data.DirectReceiverPhone = contact.Phone;
            //         currentState.data.DirectReceiverEmail = contact.Email;

            //         currentState.data.DeliveryProvince = contact.Province;
            //         currentState.data.DeliveryDistrict = contact.District;
            //         currentState.data.DeliveryWard = contact.Ward;
            //         currentState.data.DeliveryAddress = contact.Address;

            //         // currentState.instance.$setState({
            //         //   data: currentState.data,
            //         // });
            //         requireSetData = true;
            //         this.validate();
            //     });
            // }
            // if ((fieldName == 'Publisher') && fieldValue && !currentState.instance.loading) {
            //     await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

            //         // Auto fill object
            //         currentState.data.Publisher = fieldValue;
            //         currentState.data.PublisherName = contact.Name;
            //         currentState.data.PublisherPhone = contact.Phone;
            //         currentState.data.PublisherEmail = contact.Email;
            //         currentState.data.PublisherAddress = contact.Address;

            //         // currentState.instance.$setState({
            //         //   data: currentState.data,
            //         // });

            //         this.validate();
            //         requireSetData = true;
            //     });
            // }

            // if (listName == 'Details' && fieldName == 'Unit') {
            //     // let price = currentState.data[listName][index]?.Price;
            //     const detail = currentState.data[listName][index];
            //     previousUnit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(previousUnit));
            //     if (previousUnit && this.commonService.getObjectId(previousUnit) != this.commonService.getObjectId(detail.Unit)) {
            //         const nextUnit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(fieldValue));
            //         if (previousUnit.ConversionRatio && nextUnit.ConversionRatio) {
            //             detail['Quantity'] = detail['Quantity'] * previousUnit.ConversionRatio;
            //             // detail['Price'] = detail['Price'] / previousUnit.ConversionRatio;
            //             detail['Quantity'] = detail['Quantity'] / nextUnit.ConversionRatio;
            //         }
            //         detail['Price'] = nextUnit.Price;
            //         detail['ToMoney'] = detail['Price'] * detail['Quantity'];

            //         let business = [];
            //         const product = detail.Product;
            //         // if (product) {
            //         //   if (this.commonService.getObjectId(detail.Type) == 'PRODUCT') {
            //         //     const unit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(detail.Unit)) || null;
            //         //     if (unit) {
            //         //       if (unit.IsAutoAdjustInventory === false) {
            //         //         business = [{
            //         //           id: 'PURCHASESKIPWAREHOUSE',
            //         //           text: 'Mua hàng nhưng không nhập kho (công nợ)',
            //         //         }];
            //         //       }
            //         //       if (unit.IsAutoAdjustInventory === true) {
            //         //         business = [{
            //         //           id: 'PURCHASEWAREHOUSE',
            //         //           text: 'Mua hàng sau đó nhập kho (công nợ)',
            //         //         }];
            //         //       }
            //         //     }
            //         //   }
            //         //   if (this.commonService.getObjectId(detail.Type) == 'SERVICE') {
            //         //     business = [{
            //         //       id: 'PURCHASECOST',
            //         //       text: 'Chi phí phát sinh khi mua hàng: vận chuyển, xăng dầu,... (công nợ)',
            //         //     }];
            //         //   }
            //         //   detail['Business'] = business;
            //         // }
            //     }
            // }

            if (requireSetData) {
                const total = this.calculateTotal();
                console.log('collaborator form set data: ', currentState.data);
                currentState.instance.$setState({
                    data: currentState.data,
                    total: total,
                });
            } else {
                currentState.instance.$setState({ total: this.calculateTotal() });
            }

            return fieldInfo;
        });
    }

    async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
        const currentState = this.currentState;
        return new Promise((resolve, reject) => {
            self.$app.dialog.confirm('Bạn có chắc là muốn gở chi tiết này ?', 'Gở chi tiết', () => {
                resolve(super.removeDetailItem(self, e, args).then(detailInfo => {
                    let { detail, listName, index } = detailInfo;
                    if (listName == 'UnitConversions') {
                        self.$app.accordion.close(detail[0]);
                    }
                    this.calculateTotal();
                    this.rootServices.playDecreasePipSound();
                    return detailInfo;
                }).catch(err => {
                    return Promise.reject(err);
                }));
            });
        });
    }


    async chooseProduct(self: F7ComponentContextExtend) {
        // const self: F7ComponentContextExtend = this;
        const $this = this;
        const currentState = this.currentState;
        this.rootServices.navigate('/collaborator/product-list/choose', {
            context: {
                index: 'choosecollaboratorproduct',
                query: {
                    onlyBusinessProducts: false,
                    includeUnits: true,
                    includePrice: true,
                },
                page: $this.commonService.getObjectId($this.currentState.data['Page']),
                backTitle: this.title,
                textColorClass: self.textColorClass,
                async onChoose(product: ProductModel, unitId?: string) {
                    currentState.instance.$router.back();
                    console.log('Choosed product', product);

                    // const productInfo = (await $this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + product.Code, { includeIdText: true, includeUnits: true }))[0];
                    product.id = product.Code;
                    product.text = product.Name;
                    // const unit = product.UnitConversions && product.UnitConversions.find(f => f.IsDefaultPurchase) || product.UnitConversions[0]
                    // const unit = product.UnitConversions && product.UnitConversions.length == 1 && product.UnitConversions[0] || null;
                    // const unit = product.UnitList.find(f => f.id == $this.commonService.getObjectId(product.Unit)) || product.UnitList[0] || product.Unit || null;

                    // Load price
                    // const unitPriceMap = await $this.rootServices.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-table-details', {
                    //   priceTable: 'default',
                    //   eq_Code: product.Code,
                    // }).then(rs => rs.reduce((result, current, index) => {
                    //   result[current.Code + '-' + $this.commonService.getObjectId(current.Unit)] = current.Price;
                    //   return result;
                    // }, {}));

                    // for (const unit of product.Units) {
                    //   unit.Price = unitPriceMap[product.Code + '-' + $this.commonService.getObjectId(unit)];
                    // }


                    let unit = null;
                    if (unitId) {
                        unit = product.Units.find(f => $this.commonService.getObjectId(f) == unitId);
                    } else {
                        unit = product.Units.find(f => f.IsDefaultSales) || product.Units[0] || null;
                    }

                    const index = currentState.data.Details.findIndex(f => $this.commonService.getObjectId(f.Product) == product.id && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(unit));
                    if (index > -1) {
                        let item = currentState.data['Details'][index];
                        item.Quantity++;
                        $this.updateItemForList('Details', index, item);
                        $this.commonService.showInfo('Tăng số lượng lên ' + item.Quantity + ' cho sản phẩm ' + $this.commonService.getObjectText(product) + ' !', { position: 'bottom' });
                        return;
                    }

                    let business = [];
                    if ($this.commonService.getObjectId(product.Type) == 'PRODUCT') {
                        if (unit) {
                            if (unit.IsAutoAdjustInventory === false) {
                                business = [{
                                    id: 'PURCHASESKIPWAREHOUSE',
                                    text: 'Mua hàng nhưng không nhập kho (công nợ)',
                                }];
                            }
                            if (unit.IsAutoAdjustInventory === true) {
                                business = [{
                                    id: 'PURCHASEWAREHOUSE',
                                    text: 'Mua hàng sau đó nhập kho (công nợ)',
                                }];
                            }
                        }
                    }
                    if ($this.commonService.getObjectId(product.Type) == 'SERVICE') {
                        business = [{
                            id: 'PURCHASECOST',
                            text: 'Chi phí phát sinh khi mua hàng: vận chuyển, xăng dầu,... (công nợ)',
                        }];
                    }

                    $this.addItemForList('Details', {
                        ...new CollaboratorOrderDetailModel(),
                        Type: product.Type || 'PRODUCT',
                        // Business: business,
                        Product: {
                            id: product.id,
                            text: product.text,
                            Name: product.ProductName,
                            Units: product.Units,
                        },
                        Description: product.Name,
                        Unit: unit,
                        Price: unit.Price || 0,
                        Quantity: 1,
                        // Tax: 'NOTAX',
                        __unitList: product.Units,
                        Image: [
                            ...[product.FeaturePicture],
                            ...(Array.isArray(product.Pictures) && product.Pictures || []).filter(f => f.Id != product?.FeaturePicture?.Id)
                        ]
                    });

                },
            }
        });
    }

    calculateTotal(data?: CollaboratorOrderModel) {
        const currentState = this.currentState;
        let total = 0;
        const calculateData = data || currentState.data;
        for (const detail of calculateData.Details) {
            const toMoney = detail.ToMoney = (detail.Quantity || 0) * (detail.Price || 0);
            total += toMoney;
        }

        // currentState.instance.$setState({ total });
        return total;
    }

    async save(self: F7ComponentContext, option?: { postParams?: any, updateProperties?: string[] }) {
        const $this = this;
        if (!option) option = {};
        if (!option.postParams) option.postParams = {};
        option.postParams.page = $this.commonService.getObjectId($this.currentState.data.Page);

        // Do not update relative vouchers
        if (!/^new\-?/.test(this.currentState.instance?.$route?.params?.id)) {
            delete this.currentState.data.RelativeVouchers;
        }

        return super.save(self, option).then(rs => {
            this.currentState.data.State = rs.State;
            self.$setState({ data: this.currentState.data });
            return rs;
        });
    }

    async savePdf(self: F7ComponentContextExtend, e: any) {
        if (this.currentState.data.State !== 'APPROVED') {
            await this.save(self);
        }
        this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: this.currentState.data.Code, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
    }
    async changeState(self: F7ComponentContextExtend, e: any) {
        // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
        const currentState = CollaboratorStateMap.publisherCommissionStateMap[this.currentState.data.State];
        if (currentState && currentState.nextStates) {
            const supplierDialog = this.currentState.instance.$app.dialog.create({
                cssClass: 'dialog-large',
                title: 'Chuyển trạng thái',
                text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
                buttons: [
                    ...currentState.nextStates.map((m, index) => ({
                        text: (index + 1) + '. ' + m.confirmLabel,
                        color: 'success',
                        onClick: () => {
                            this.rootServices.apiService.putPromise(this.apiPath + '/' + this.currentState.data.Code, {
                                changeState: m.state,
                                page: this.commonService.getObjectId(this.currentState.data['Page'])
                            }, [{ Code: this.currentState.data.Code }]).then(rs => {
                                this.currentState.data.State = m.state;
                                this.setData(this.currentState.data);

                                this.currentState.lastAction = 'UPDATE_SUCCESS';
                            }).catch(err => {
                                this.commonService.showError(err);
                            });
                        }
                    })),
                    {
                        text: 'Trở về',
                        bold: true,
                        color: 'red'
                    },
                ],
                verticalButtons: true,
            });
            supplierDialog.open();
        }

        // this.currentState.instance.$app.dialog.confirm('Bạn có muốn ' + (newState == 'APPROVED' ? 'duyệt' : 'bỏ duyệt') + ' phiếu báo giá `' + this.currentState.data.Title + '`', 'Thay đổi trạng thái báo giá', async () => {

        //   if (this.currentState.data.State !== 'APPROVED') {
        //     await this.save(self);
        //   }
        //   await this.rootServices.apiService.putPromise<CollaboratorOrderModel>(this.apiPath, {
        //     changeState: newState,
        //   }, [{ Code: this.currentState.data.Code }]).then(rs => {
        //     if (newState == 'APPROVED') {
        //       this.commonService.showInfo('Đã duyệt phiếu đặt mua hàng !', { position: 'bottom' });
        //     } else {
        //       this.commonService.showInfo('Đã bỏ duyệt phiếu đặt mua hàng !', { position: 'bottom' });
        //     }
        //   });
        //   this.currentState.data.State = newState;
        //   this.setData(this.currentState.data);

        // });
    }



    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'collaborator-order-form',
            path: '/page/collaborator/report/publisher/commission/form/:id',
            component: {
                template: /*html*/`
            <div class="page page-form collaborator-order-form no-toolbar-x" data-name="collaborator-order-form">
                <div class="navbar">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner sliding">
                        <div class="left">
                            <a class="link back {{textColorClass}}">
                                <i class="icon icon-back"></i>
                                <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                            </a>
                        </div>
                        <div class="title">${this.title}</div>
                        <div class="right">
                        
                        </div>
                    </div>
                </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                    <div class="preloader"></div>
                    <div class="ptr-arrow"></div>
                </div>

                <form class="data-form">
                    <div class="list profile-form no-hairlines-md inset main-form">
                        <ul>
                            <li class="item-content item-input">
                            <div class="item-inner">
                                <div class="item-title item-label">ID phiếu (*)</div>
                                <div class="item-input-wrap">
                                <input @change="onFieldChange" disabled class="field text-color-blue" name="Code" type="text" placeholder="ID phiếu, auto..." value="{{data.Code}}">
                                </div>
                            </div>
                            </li>
                            {{#if copyFromId}}
                            <li class="item-content">
                            <div class="item-inner">
                                <div class="item-input-wrap text-color-orange">
                                    <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên phiếu copy</span>
                                </div>
                            </div>
                            </li>
                            {{/if}}
                            <li class="item-link smart-select smart-select-init smart-select-roles {{validate data.Page schema 'Page'}}" name="Page" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                            <select>
                                <option value="">Chon...</option>
                                {{#each pageList}}
                                <option value="{{id}}">{{text}}</option>
                                {{/each}}
                            </select>
                            <div class="item-content">
                                <div class="item-inner">
                                <div class="item-title">Trang</div>
                                <div class="item-after text-color-blue"></div>
                                </div>
                            </div>
                            </li>
                        </ul>
                    </div>

                    <div class="block-title">Thông tin CTV</div>
                    <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                        <li class="autocomplete" name="Object">
                            <div class="item-link item-content" href="#">
                                <div class="item-inner">
                                    <div class="item-title">CTV</div>
                                    <div class="item-after text-color-blue {{validate data.Object schema 'Object'}}">{{objecttext this "data.Object" "CTV..."}}</div>
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-inner">
                                <div class="item-title item-label {{validate data.ObjectName schema 'ObjectName'}}">Tên (*)</div>
                                <div class="item-input-wrap">
                                    <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="ObjectName" type="text" placeholder="Tên..." value="{{data.ObjectName}}">
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-inner">
                                <div class="item-title item-label">Số điện thoại (*)</div>
                                <div class="item-input-wrap">
                                <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="ObjectPhone" type="text" placeholder="SĐT..."
                                    value="{{data.ObjectPhone}}">
                                
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-inner">
                                <div class="item-title item-label">Email (*)</div>
                                <div class="item-input-wrap">
                                <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="ObjectEmail" type="text" placeholder="Email..."
                                    value="{{data.ObjectEmail}}">
                                
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-inner">
                                <div class="item-title item-label">Địa chỉ (*)</div>
                                <div class="item-input-wrap">
                                <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="ObjectAddress" type="text" placeholder="ĐC..."
                                    value="{{data.ObjectAddress}}">
                                
                                </div>
                            </div>
                        </li>
                    </ul>
                    </div>

                    <div class="block-title">Thông tin chung</div>
                    <div class="list profile-form no-hairlines-md inset main-form">
                        <ul>
                            <li class="item-content item-input">
                            <div class="item-inner">
                                <div class="item-title item-label {{validate data.Title schema 'Title'}}">Tiêu đề (*)</div>
                                <div class="item-input-wrap">
                                    <input @keyup="onFieldChange" class="field text-color-blue" name="Title" type="text" placeholder="Tiêu đề..." value="{{data.Title}}">
                                </div>
                            </div>
                            </li>
                        </ul>
                    </div>
                  
                    <div class="block">
                        <!--<p class="row">
                            <a class="col-50 button button-fill button-large color-blue {{#if validates}}disabled{{/if}}"  @click="save"> Lưu lại</a>
                            <a class="col-50 button button-fill button-large color-red  {{#if validates}}disabled{{/if}}" @click="savePdf">Save PDF</a>
                        </p>-->
                        <p class="row">
                            <a class="col-100 button button-fill button-large color-{{js "this.data.State && this.processMap && this.processMap[this.data.State] && this.processMap[this.data.State].color"}}  {{#if validates}}disabled{{/if}}" @click="changeState">{{js "this.data.State && this.processMap && this.processMap[this.data.State] && this.processMap[this.data.State].label"}}</a>
                        </p>
                    </div>

                    <div class="tabs-swipeable-wrap" style="height: initial;">
                    <div class="tabs">
                        
                        <div id="report-tab-1" class="page-content tab tab-active" style="padding-top: 0px">
                        <div class="block-title">Báo cáo theo chứng từ<a class="button button-small button-fill tab-link" href="#report-tab-2" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                        {{#each data.PartDetails}}
                            <div class="block-title">{{ReportPartLabel}}</div>
                            <div class="list media-list workplace-list inset">
                                <ul>
                                    <li>
                                        <div class="item-content">
                                            <div class="item-inner">
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">bag_fill</i>Doanh số</div>
                                                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'GenerateCredit' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">doc_checkmark_fill</i>Đã Thu được {{GeneratePaidRatio}}%</div>
                                                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'GeneratePaidAmount' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Cơ bản</div>
                                                    <div class="text-color-green" style="float: right">{{objectcurrency this 'BasicCommissionAmount' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Nâng cao</div>
                                                    <div class="text-color-green" style="float: right">{{objectcurrency this 'AdvanceCommissionAmount' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Add-on</div>
                                                    <div class="text-color-green" style="float: right">{{objectcurrency this 'AddonCommissionAmount' '--'}}
                                                    </div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt_fill</i>Tổng chiết khấu</div>
                                                    <div class="text-color-lightblue" style="float: right">{{objectcurrency this 'TotalCommissionAmount' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_fill</i>CK thực nhận kỳ trước</div>
                                                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'RealTotalCommissionAmount' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_fill</i>CK thực nhận</div>
                                                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'RealTotalCommissionAmount' '--'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="list media-list workplace-list inset">
                                <ul>
                                    {{#each Details}}
                                    <li data-id="{{Voucher}}" @click="gotoOrder">
                                        <a class="item-link item-content">
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title text-color-default description" style="font-size: 0.9rem">{{Voucher}} - {{VoucherDescription}}</div>
                                                    <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                        <span class="badge color-{{StateColor}} unread">{{StateText}}</span>
                                                    </div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">person_crop_square_fill</i>{{ObjectName}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>Ngày</div>
                                                    <div class="text-color-gray lastUpdateMoment" style="float: right">{{date VoucherDate}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">bag_fill</i>Doanh số</div>
                                                    <div class="text-color-blue" style="float: right">{{currency GenerateCredit}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">doc_checkmark_fill</i>Đã Thu được {{GeneratePaidRatio}}%</div>
                                                    <div class="text-color-blue" style="float: right">{{currency GeneratePaidAmount}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Cơ bản</div>
                                                    <div class="text-color-green" style="float: right">{{currency BasicCommissionAmount}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Nâng cao</div>
                                                    <div class="text-color-green" style="float: right">{{currency AdvanceCommissionAmount}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label">
                                                        <i class="icon f7-icons">gift_alt</i>CK Add-on
                                                    </div>
                                                    <div class="text-color-green" style="float: right">{{currency AddonCommissionAmount}}
                                                    </div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt_fill</i>Tổng chiết khấu</div>
                                                    <div class="text-color-lightblue" style="float: right">{{currency TotalCommissionAmount}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_fill</i>CK thực nhận</div>
                                                    <div class="text-color-blue" style="float: right">{{currency RealTotalCommissionAmount}}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    {{/each}}
                                </ul>
                            </div>
                            {{/each}}
                        </div>

                        <div id="report-tab-2" class="page-content tab" style="padding-top: 0px">
                            <div class="block-title">Báo cáo theo sản phẩm<a class="button button-small button-fill tab-link" href="#report-tab-1" style="float: right; font-size: 0.7rem; font-weight: bold">prev</a></div>

                            <div class="list media-list workplace-list inset">
                                <ul>
                                    <li>
                                        <div class="item-content">
                                            <div class="item-inner">
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">bag_fill</i>Doanh số</div>
                                                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'data.MainSummaryForProduct.GenerateCredit' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">doc_checkmark_fill</i>Đã Thu được {{GeneratePaidRatio}}%</div>
                                                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'data.MainSummaryForProduct.GeneratePaidAmount' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Cơ bản</div>
                                                    <div class="text-color-green" style="float: right">{{objectcurrency this 'data.MainSummaryForProduct.BasicCommissionAmount' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Nâng cao</div>
                                                    <div class="text-color-green" style="float: right">{{objectcurrency this 'data.MainSummaryForProduct.AdvanceCommissionAmount' '--'}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Add-on</div>
                                                    <div class="text-color-green" style="float: right">{{objectcurrency this 'data.MainSummaryForProduct.AddonCommissionAmount' '--'}}
                                                    </div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt_fill</i>Tổng chiết khấu</div>
                                                    <div class="text-color-lightblue" style="float: right">{{objectcurrency this 'data.MainSummaryForProduct.TotalCommissionAmount' '--'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="list media-list workplace-list inset">
                                <ul>
                                    {{#each data.DetailsOfReportByProduct}}
                                    <li data-id="{{Voucher}}" @click="gotoOrder">
                                        <a class="item-link item-content">
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title text-color-default description" style="font-size: 0.9rem">{{Voucher}} - {{VoucherDescription}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">person_crop_square_fill</i>{{objecttext this "Product" "--"}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">bag_fill</i>Doanh số</div>
                                                    <div class="text-color-blue" style="float: right">{{currency GenerateCredit}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">doc_checkmark_fill</i>Số lượng</div>
                                                    <div class="text-color-blue" style="float: right">{{decimal GenerateCreditQuantity}} {{objecttext this "ProductUnit" "--"}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">doc_checkmark_fill</i>Đã Thu được</div>
                                                    <div class="text-color-blue" style="float: right">{{currency GeneratePaidAmount}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Cơ bản {{BasicCommissionRatio}}%</div>
                                                    <div class="text-color-green" style="float: right">{{currency BasicCommissionAmount}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Nâng cao {{AdvanceCommissionRatio}}%</div>
                                                    <div class="text-color-green" style="float: right">{{currency AdvanceCommissionAmount}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Add-on  {{AddonCommissionRatio}}%</div>
                                                    <div class="text-color-green" style="float: right">{{currency AddonCommissionAmount}}</div>
                                                </div>
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt_fill</i>Tổng chiết khấu</div>
                                                    <div class="text-color-lightblue" style="float: right">{{currency TotalCommissionAmount}}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    {{/each}}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {{#if data.RelativeVouchers}}
                <div class="block-title">Chứng từ liên quan</div>
                <div class="list media-list inset activity-list">
                    <ul>
                        {{#each data.RelativeVouchers}}
                        <li class="swipeout" data-id="{{id}}" data-type="{{type}}">
                        <a class="item-content item-link swipeout-content">
                            <div class="item-inner">
                            <div class="item-title-row">
                                <div class="item-title">{{type.symbol}}: {{text}}</div>
                                <div class="item-after; text-color-blue" style="font-size: 0.8rem"><span class="badge color-{{stateStatus}} unread nowrap">{{text state}}</span></div>
                            </div>
                            <div class="item-subtitle">Ngày: {{date date}}</div>
                            <div class="item-text">{{text type}}:{{id}}</div>
                            </div>
                        </a>
                        <div class="swipeout-actions-right">
                            <a href="#" class="color-green swipeout-overswipe">Duyệt</a>
                        </div>
                        </li>
                        {{/each}}
                    </ul>
                </div>
                {{/if}}

                {{#if validates}}
                <div class="block-title text-color-arange">Cảnh báo</div>
                    <div class="list inset">
                        <ul>
                            {{#each validates}}
                            <li>
                                <div class="item-content">
                                    <div class="item-inner">
                                    <div class="item-title">{{validates[0].label}}</div>
                                    <div class="item-after text-color-orange">{{validates[0].text}}</div>
                                    </div>
                                </div>
                            </li>
                            {{/each}}
                        </ul>
                    </div>
                {{/if}}
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
                style: /*css*/`
          .page.collaborator-order-form .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .page.collaborator-order-form .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .page.collaborator-order-form .pictures::-webkit-scrollbar {
            display: none;
          }

          .page.collaborator-order-form .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .page.collaborator-order-form .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }
        `,
                data() {
                    return {
                        title: $this.title,
                        reminder: {
                            No: '234234',
                        },
                        data: new CollaboratorOrderModel(),
                        contactMemebets: [],
                        // contactList: [],
                        validates: null,
                    };
                },
                methods: {
                    gotoOrder(e) {
                        const self: F7ComponentContextExtend = this;
                        let orderId = $(e.currentTarget).data('id');
                        const pageId = self.$route.context['pageInfo'] && self.$route.context['pageInfo']['Code'] || null;

                        $this.rootServices.navigate(`/page-collaborator-order/${pageId}/${orderId}`, {
                            context: {
                                pageId: pageId,
                                callback: (data: CollaboratorOrderModel, state: any) => {
                                    console.log('Callback data: ', data);
                                }
                            }
                        });
                    },
                    previewPictures(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.currentState;

                        let index = $(e.target).data('index');
                        if (typeof index == 'undefined') {
                            const id = $(e.target).attr('data-id');
                            index = currentState.data.Attachments.findIndex(f => f.Id == id);
                        }
                        $this.rootServices.previewPictures(currentState.data.Attachments, index || 0, { addToStackButton: true });
                    },
                    changeState(e) {
                        $this.changeState(this, e);
                    },
                    savePdf(e) {
                        $this.savePdf(this, e);
                    },
                    onFieldChange(e) {
                        $this.onFieldChange(this, e);
                    },
                    async refresh(e, done) {

                        done && setTimeout(() => done(), 10000);
                        $this.refresh(this).then(data => {
                            done && done();
                        });

                        return true;
                    },
                    save() {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.currentState;
                        const relativeVouchers = self.$route.context.relativeVouchers;
                        const id = currentState.data.Code || self.$route.params['id'];

                        $this.commonService.showPreloader();

                        const preloaderProcess = setTimeout(() => {
                            $this.commonService.hidePreloader();
                        }, 15000);

                        if (id === 'new') {
                            if (relativeVouchers) {
                                currentState.data.RelativeVouchers = relativeVouchers;
                            }
                        }

                        $this.save(self).then(rs => {
                            $this.commonService.showInfo("Đã lưu đon đặt mua hàng", { position: 'bottom' });
                            clearTimeout(preloaderProcess);
                            $this.commonService.hidePreloader();
                        }).catch(err => {
                            clearTimeout(preloaderProcess);
                            $this.commonService.hidePreloader();
                            console.error(err);
                        });
                    },
                },
                on: {
                    // pageMounted(e, page) {
                    //     console.log('page mounted');
                    // },
                    pageInit(e, page: F7Page) {
                        console.log('page init');
                        const self: F7ComponentContextExtend = this;

                        $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
                            // self.detailDialog = self.$app.dialog.create({
                            //   el: page.$el.find('.dialog-detail'),
                            //   closeByBackdropClick: true,
                            // });
                        });

                        $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
                            self.$setState({
                                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
                            });
                        }).catch(err => {
                            console.error(err);
                            $this.commonService.showError(err, { position: 'bottom' });
                        });
                    },
                    // pageBeforeIn(e, page) {
                    //     console.log('page before in');
                    //     const self: F7ComponentContextExtend = this;
                    //     self.refresh();
                    // },
                    // pageAfterIn(e, page) {
                    //     console.log('page after in');
                    // },
                    pageBeforeOut(e, page) {
                        console.log('page before out');
                        const self: F7ComponentContextExtend = this;
                        // Call caback function
                        if (this.$route?.context['callback']) {
                            this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
                        }

                        // Auto save
                        // $this.currentState?.data?.Code && $this.currentState?.data?.State != 'APPROVED' && self.save();
                    },
                    // pageAfterOut(e, page) {
                    //     console.log('page after out');
                    // },
                    // pageBeforeUnmount(e, page) {
                    //     console.log('page before unmount');
                    // },
                    // pageBeforeRemove(e, page) {
                    //     console.log('page before remove');
                    // },
                    pageBeforeRemove(e, page) {
                        console.log('[page event] before remove', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
                        // starmtSelectRoles.destroy();
                        const currentState = $this.state[self.$route.params.id];
                        if (self.$route.params.id === 'new') {
                            if (currentState && currentState.data && currentState.data.Code) {
                                delete currentState.data;
                            }
                        }
                    },
                },
            },
        };
    }
}
