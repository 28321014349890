import { AuthUser } from './../../../../vendors/smart-bot-app/src/app/services/auth.service';
import { F7ComponentContext, F7Page } from '../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { TaskReminderModel } from 'vendors/smart-bot-app/src/app/model/task.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import * as moment from 'moment';
import { PageComponent } from '../page.component';
import { AccountModel } from 'vendors/smart-bot-app/src/app/model/accounting.model';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

/** Component group manager */
export class PageHomeComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Trang chủ'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public parentComponent: PageComponent,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');

    this.parentComponent.eventEmitter.subscribe((e: { name: string, data: any }) => {
      switch (e.name) {
        case 'refresh':
          this.currentState?.instance?.refresh && this.currentState?.instance?.refresh();
          break;
      }
    });
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {
      this.commonService.waitFor(300, 100, () => state.instance.pageInfo).then(() => {

        const pageId = state.instance.pageInfo?.Code;
        this.rootServices.authService.isAuthenticated().subscribe(async authenticated => {
          let user: AuthUser;
          if (authenticated) {
            user = await this.rootServices.authService.user$.pipe(filter(f => !!f), take(1)).toPromise();
            let publisherCv = await this.rootServices.apiService.getPromise<any>('/collaborator/pages', {
              getPublisherCv: true,
              includeOrganization: true,
              page: this.currentState.instance.$route?.params['id'],
              pageTag: this.currentState.instance.$route?.context && this.currentState.instance.$route?.context['pageTag'],
              publisher: user.id,
              includeRefUserInfo: true,
              includeBusinessProducts: true,
            });
            if (publisherCv && publisherCv.State == 'APPROVED') {
              this.commonService.showInfo('Hồ sơ CTV Bán Hàng của bạn đã được duyệt');
              this.commonService.navigate('/page/' + pageId + '/collaborator/products', { animate: false, context: { pageTag: state.instance.pageInfo?.TagName } });
            }
            this.currentState.instance.$setState({
              authenticated: authenticated,
              pagePublisherState: publisherCv.State,
              publisherCv: publisherCv,
              register: {
                Name: user.name,
                Phone: user.phone,
              },
            });
          }
        });

      });

      return currentState;
    });
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  preapreProduct(p: ProductModel) {
    if (p.Categories && p.Categories.length > 0) {
      p.CategoriesText = p.Categories.map(m => m.text).join(', ');
    }
    if (p.FeaturePicture && p.FeaturePicture.Thumbnail) {
      p.FeaturePictureThumbnail = p.FeaturePicture.Thumbnail;
    }
    const taxValue = p.Tax && p.Tax.Tax || 0;
    p.ToMoney = parseInt(p.Price as any) + parseInt(p.Price as any) * parseInt(taxValue as any) / 100;
    p.ToMoneyText = this.rootServices.currencyPipe.transform(p.ToMoney, 'VND');;
    p.PriceText = this.rootServices.currencyPipe.transform(p.Price, 'VND');
    if (p.OriginPrice) {
      p.OriginPriceText = this.rootServices.currencyPipe.transform(p.OriginPrice, 'VND');
    }
    return p;
  }

  onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    // const self: F7ComponentContext = this;
    const field = e.target;
    let fieldName, fieldValue, index, listName;
    fieldName = $(field).attr('name');
    // console.log(e.key);

    if ($(field).is('[type="checkbox"')) {
      fieldValue = $(field).is(':checked') || false;
    } else {
      fieldValue = field.value;
    }

    self.register = self.register || {};
    self.register[fieldName] = fieldValue;

    self.notvalidated = false;
    self.regnotvalidated = false;

    // if (!self.register.Term1 || !self.register.Phone || !self.allowRegister) {
    //   self.notvalidated = true;
    // }

    // if (!self.register.RegTerm1 || !self.allowRegister || !self.register.Phone || !self.register.Name) {
    //   self.regnotvalidated = true;
    // }
    const data: any = {
      notvalidated: self.notvalidated,
      regnotvalidated: self.regnotvalidated,
    };
    if (typeof self.allowRegister == 'undefined') {
      self.allowRegister = true;
    }
    if (fieldName == 'Phone' && fieldValue.length > 9) {
      this.commonService.takeUntil('auto-detect-account-info', 300).then(() => {
        // self.$setState({
        //   authoDetectNameMessage: ' - Đang kiểm tra số điện thoại...',
        // });
        this.rootServices.apiService.putPromise<AccountModel>('/collaborator/pages', { getPublisherInfoByPhone: fieldValue, page: self.pageId }, []).then(accoutnInfo => {
          data.register = self.register;
          data.register.Name = '';
          if (accoutnInfo) {
            if (!accoutnInfo.Publisher) {
              if (accoutnInfo.Name) {
                data.register.Name = accoutnInfo.Name;
                data.authoDetectNameMessage = ' - Đã đăng ký tài khoản ProBox.vn';
              }
              data.allowRegister = true;
            } else {
              if (!accoutnInfo.Publisher.State || accoutnInfo.Publisher.State == 'PENDING') {
                data.authoDetectNameMessage = ' - Đang chờ xác nhận OTP';
                data.allowRegister = true;
                if (accoutnInfo.Name) {
                  data.register = self.register;
                  data.register.Name = accoutnInfo.Name;
                }
              }
              if (accoutnInfo.Publisher.State == 'REGISTER' || accoutnInfo.Publisher.State == 'REGISTERED') {
                data.authoDetectNameMessage = ' - Hồ sơ đang chờ doanh nghiệp duyệt';
                data.allowRegister = false;
              }
              if (accoutnInfo.Publisher.State == 'BLOCKED') {
                data.authoDetectNameMessage = ' - Bị chặn bởi doanh nghiệp';
                data.allowRegister = false;
              }
              if (accoutnInfo.Publisher.State == 'APPROVED') {
                data.authoDetectNameMessage = ' - Hồ sơ đã được duyệt bởi doanh nghiệp';
                data.allowRegister = false;
              }
            }

            if (!self.register.RegTerm1 || !data.allowRegister || !data.register.Phone || !data.register.Name) {
              data.regnotvalidated = true;
            }

            self.$setState(data);
          }
        });
      });
    } else {

      if (!self.register.RegTerm1 || !self.allowRegister || !self.register.Phone || !self.register.Name) {
        data.regnotvalidated = true;
      }

      self.$setState(data);
    }

    console.log(self.register);
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      // Tab path
      path: '/',
      // Tab id
      id: 'home',
      // Fill this tab content from content string
      component: {
        template: /*html*/`
          <div class="page-content" style="padding-top: 0">
            
            <div class="block block-strong inset" style="margin-top: 0">{{js "this.pageInfo && this.pageInfo.Description"}}</div>
            
            <div class="block block-strong inset">
              <b>{{js "this.pageInfo && this.pageInfo.Name"}} sử dụng phần mềm CRM cá nhân ProBox.vn để tương tác với Cơ hội bán hàng và Đơn hàng của anh chị</b>
            </div>

            <!-- Product List
            {{#js_if "this.productList && this.productList.length > 0"}}
            <div class="block-title">Sản phẩm kinh doanh</div>
            <div class="list media-list inset">
              <ul>
                {{#each productList}}
                <li data-id="{{Code}}" @click="gotoProduct">
                  <a href="#" class="item-link item-content">
                    <div class="item-media">
                      <i class="icon list-item-img" style="border-radius:0.3rem; background-image: url({{js "this.FeaturePictureThumbnail"}}); width: 44px; height: 44px"></i></div>
                    <div class="item-inner">
                      <div class="item-text">{{Name}}</div>
                      <div class="item-subtitle">{{CategoriesText}}</div>
                    </div>
                  </a>
                </li>
                {{/each}}
              </ul>
            </div>
            {{else}}
            <div class="block-title skeleton-text skeleton-effect-blink">Sản phẩm kinh doanh</div>
            <div class="list media-list inset skeleton-text skeleton-effect-blink">
              <ul>
                <li>
                  <a href="#" class="item-link item-content">
                    <div class="item-media">
                      <i class="icon list-item-img" style="border-radius:0.3rem; background-image: url(assets/images/no-image-available.png); width: 44px; height: 44px"></i></div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">Name Name Name Name Name </div>
                      </div>
                      <div class="item-subtitle">Categories Text</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            {{/js_if}}
            <div class="block">
              <div class="row">
                <div class="col-50">
                  <a class="col button button-outline color-orange button-large link{{js "this.beginOfProductList && ' disabled' || ''"}}" @click="prevProductPage"><i class="icon f7-icons">arrow_left_square_fill</i></a>
                </div>
                <div class="col-50">
                  <a class="col button button-outline color-orange button-large link{{js "this.endOfProductList && ' disabled' || ''"}}" @click="nextProductPage"><i class="icon f7-icons">arrow_right_square_fill</i></a>
                </div>
              </div>
            </div>
            <div class="block-footer">
              <div>
                Các sản phẩm trang này đang kinh doanh, hãy chọn sản phẩm phù hợp với mình và đăng ký làm cộng tác viên của trang.
              </div>
            </div>
            End Product List -->

            {{#js_if "!this.publisherCv || !this.publisherCv.State || this.publisherCv.State == 'PENDING'"}}

              {{#js_if "!this.register || !this.register.Code"}}
              <!--Đăng ký-->
              <div class="block block-strong inset" style="overflow: hidden">
                <b>Nếu anh chị chưa có tài khoản ProBox.vn<br>Chỉ cần nhập số điện thoại và xác nhận số điện thoại bằng Zalo OTP để đăng ký CTV Bán Hàng với {{js "this.pageInfo && this.pageInfo.Name"}}</b>
                <br>
                <div class="list media-list profile-form no-hairlines-md main-form" style="margin: -1rem; margin-top: 1rem;">
                  <ul>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Số điện thoại <span class="text-color-red">(*)</span> <span class="text-color-red" style="font-weight: bold">{{authoDetectNameMessage}}</span></div>
                        <div class="item-input-wrap">
                          <input @keyup="onFieldChange" class="field text-color-bluex" name="Phone" type="text" placeholder="Dùng để nhận mã Zalo OTP..."
                            value="{{register.Phone}}">
                          
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label {{validate register.Name schema 'Name'}}">Tên <span class="text-color-red">(*)</span></div>
                        <div class="item-input-wrap">
                          <input @keyup="onFieldChange" class="field text-color-bluex" name="Name" type="text" placeholder="Tên..."
                            value="{{register.Name}}">
                          
                        </div>
                      </div>
                    </li>
                    <!--<li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Email</div>
                        <div class="item-input-wrap">
                          <input @keyup="onFieldChange" class="field text-color-bluex" name="Email" type="text" placeholder="Email..."
                            value="{{register.Email}}">
                          
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Địa chỉ</div>
                        <div class="item-input-wrap">
                          <input @keyup="onFieldChange" class="field text-color-bluex" name="Address" type="text" placeholder="Địa chỉ..."
                            value="{{register.Address}}">                        
                        </div>
                      </div>
                    </li>-->
                    <li>
                      <label class="item-checkbox item-content">
                        <input type="checkbox" name="RegTerm1" value="1" @change="onFieldChange"/>
                        <i class="icon icon-checkbox"></i>
                        <div class="item-inner no-line">
                          <div class="item-title">Điều khoản <span class="text-color-red">(*)</span></div>
                          <div class="item-text" style="-webkit-line-clamp: 100; max-height: initial">Đồng ý cho ProBox JSC nhắn tin Zalo OTP, ZNS. Cho phép {{js "this.pageInfo && this.pageInfo.Organization && this.pageInfo.Organization.companyName"}} chủ động nhắn tin đến tài khoản ProBox.vn của {{register.Name}} và cho phép ProBox.vn cung cấp thông tin tên số điện thoại khi đăng ký CTV Bán Hàng. Cho phép {{js "this.pageInfo && this.pageInfo.Organization && this.pageInfo.Organization.companyName"}} gọi điện đến {{register.Name}} để tư vấn chương trình CTV Bán Hàng</div>
                        </div>
                      </label>
                    </li>
                    <li class="item-content item-input" style="padding-left: 0">
                      <a class="col button button-fill color-red button-large link {{#if regnotvalidated}}disabled{{/if}}" @click="registerAccountAndBmPublisher" style="width: 100%; border-radius: initial;">
                      {{#js_if "this.allowRegister"}}
                      Xác nhận đăng ký qua Zalo OTP
                      {{else}}
                      Không cho phép đăng ký
                      {{/js_if}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {{else}}
              <div class="block-title">Đăng ký thành công</div>
              <div class="block block-strong inset" style="margin-top: 0">
                <b>Cám ơn {{register.Name}} đã đăng ký chương trình CTV Bán Hàng với {{js "this.pageInfo && this.pageInfo.Organization && this.pageInfo.Organization.companyName"}}<br>
                Sẽ có Nhân viên: {{js "this.pageInfo && this.pageInfo.Organization && this.pageInfo.RefUserInfo.Name"}} gọi lại tư vấn và hỗ trợ {{register.Name}} hoàn tất đăng ký.<br>
                Thông tin của nhân viên:<br>
                Số tổng đài: {{js "this.pageInfo && this.pageInfo.Organization && this.pageInfo.Organization.tel || '--'"}}<br>
                Số máy nhánh: {{js "this.pageInfo && this.pageInfo.RefUserInfo && this.pageInfo.RefUserInfo.ExtNumber || '--'"}}<br>
                Số di động/zalo: {{js "this.pageInfo && this.pageInfo.RefUserInfo && this.pageInfo.RefUserInfo.ComMobilePhone || '--'"}}</b>
              </div>
              {{/js_if}}

            {{/js_if}}

            {{#js_if "this.publisherCv.State == 'REGISTERED'"}}
            <div class="block block-strong inset" style="margin-top: 0">
              <b>CV CTV Bán Hàng của bạn đang chờ phê duyệt</b>
            </div>
            {{/js_if}}

            {{#js_if "this.publisherCv.State == 'APPROVED'"}}
            <div class="block block-strong inset" style="margin-top: 0">
              <b>CV CTV Bán Hàng của bạn đã được phê duyệt</b>
            </div>
            {{/js_if}}
          </div>
          `,
        style: /*css*/`
        :root {
          --f7-popup-tablet-width: 430px;
        }
        `,
        data() {
          return {
            title: $this.title,
            backTitle: 'Back',
            pageInfo: {},
            register: {},
            validates: null,
            processing: false,
            notvalidated: true,
            regnotvalidated: true,
            authoDetectNameMessage: '',
            authenticated: false,
            pagePublisherState: false,
            publisherCv: {},
            user: null,
            productList: [],
            productOffset: 0,
            endOfProductList: false,
          };
        },
        methods: {
          async nextProductPage(e) {
            const self: F7ComponentContextExtend = this;
            const pageId = self.pageId;
            self.productOffset += 10;
            const productList = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/products', {
              includeCategories: true,
              includeGroups: true,
              // filterByLevelAndBadge: true,
              includeSubscribed: true,
              sort_Id: true,
              page: pageId,
              search: self?.searchBar?.query && encodeURIComponent(self.searchBar.query) || '',
              includeFeaturePicture: true,
              includeUnit: true,
              offset: self.productOffset,
              limit: 10,
              skipAuth: true,
              getPublicProducts: true,
              productOfPage: true,
              onlyBusinessProducts: true,
              ...self.filter,
            }).then(products => {
              // self.$$(page.el).find('.page-content').scrollTo(0, 0);
              return products.map(t => $this.preapreProduct(t));
            });

            self.$setState({
              productList: productList,
              productOffset: self.productOffset,
              endOfProductList: productList.length < 10,
              beginOfProductList: self.productOffset == 0,
            });

          },
          async prevProductPage(e) {
            const self: F7ComponentContextExtend = this;

            const pageId = self.pageId;
            self.productOffset -= 10;
            const productList = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/products', {
              includeCategories: true,
              includeGroups: true,
              // filterByLevelAndBadge: true,
              includeSubscribed: true,
              sort_Id: true,
              page: pageId,
              search: self?.searchBar?.query && encodeURIComponent(self.searchBar.query) || '',
              includeFeaturePicture: true,
              includeUnit: true,
              offset: self.productOffset,
              limit: 10,
              skipAuth: true,
              getPublicProducts: true,
              productOfPage: true,
              onlyBusinessProducts: true,
              ...self.filter,
            }).then(products => {
              // self.$$(page.el).find('.page-content').scrollTo(0, 0);
              return products.map(t => $this.preapreProduct(t));
            });

            self.$setState({
              productList: productList,
              productOffset: self.productOffset,
              endOfProductList: productList.length < 10,
              beginOfProductList: self.productOffset == 0,
            });
          },
          gotoProduct(e) {
            const self: F7ComponentContextExtend = this;
            // const pageId = self.$route.params?.id;
            // const currentState = $this.state[]
            const id = self.$(e.target).closest('li').data('id').replace(/_\w+/, '').toLowerCase();
            $this.rootServices.navigate(`/${self.pageId.toLowerCase()}/ctvbanhang/product/${id}`, { context: { pageId: self.pageId, pageInfo: self.pageInfo, onProductChoose: self.onProductChoose } });
          },
          onFieldChange(e) {
            const self: F7ComponentContextExtend = this;
            $this.onFieldChange(self, e);
          },
          registerAccountAndBmPublisher(e) {
            const self: F7ComponentContextExtend = this;
            // self.$app.dialog.confirm(`Bạn có muốn trở thành CTV bán hàng của ${self.pageInfo?.Name}`, 'Đăng ký CTV bán hàng', () => {

            // });

            self.$setState({ notvalidated: true, regnotvalidated: true });
            self.$app.preloader.show();
            $this.rootServices.apiService.putPromise('/collaborator/pages/' + self.pageInfo?.Code, { skipAuth: true, selfRegisterPublisher: true, refId: self.$route.context['refId'] }, [{
              Code: self.pageInfo?.Code,
              Subscriber: {
                ...self.register,
                RefId: self.$route.context['refId'],
                // Case: 'NOPROBOXVNACCOUNT',
              },
            }]).then(async rs => {
              self.$app.preloader.hide();
              if (rs[0].Subscriber?.State == 'REGISTERED') {
                $this.commonService.showInfo('Chúc mừng bạn đã trở thành CTV Bán hàng của ' + self.pageInfo?.Name + ' !');
                return;
              }
              self.$setState({ notvalidated: false, regnotvalidated: false });
              let tryTime = 1;
              return await $this.rootServices.enterPasscodePopup(
                'Điền mã xác thực OTP',
                  /*html*/ `
                      <div class="block-title">Bạn hãy vào Zalo để kiểm tra tin nhắn OTP</div>
                      <div class="block block-strong inset" style="padding: 0; overflow: hidden; line-height: 0">
                          <img src="assets/images/zalo-otp.png" style="width: 100%">
                      </div>
                  `, 4, {
                swipeToClose: false,
                backLabel: 'Skip',
                onFullFill: (otp_1) => {
                  self.$app.preloader.show();
                  return $this.rootServices.apiService.putPromise('/collaborator/pages/' + self.pageInfo?.Code, { skipAuth: true, selfRegisterPublisherVerifyOpt: true, refId: self.$route.context['refId'] }, [{
                    Code: self.pageInfo?.Code,
                    Subscriber: {
                      ...self.register,
                      RefId: self.$route.context['refId'],
                      Otp: otp_1,
                    },
                  }]).then(async (rs_1) => {
                    self.$app.preloader.hide();
                    $this.commonService.showInfo('Xác thực thành công !');

                    self.$setState({ processing: false });
                    $this.commonService.showInfo('Chúc mừng bạn đã trở thành CTV Bán hàng của ' + self.pageInfo?.Name + ' !');
                    self.register.Code = rs[0].Code;
                    self.$setState({
                      register: self.register,
                    });

                    return true;
                  }).catch(err_3 => {
                    self.$app.preloader.hide();
                    $this.commonService.showError(err_3, { position: 'bottom' });
                    tryTime++;
                    if (tryTime <= 3) {
                      // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                      return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                    }
                    tryTime = 1;
                    return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                  });
                }
              }).catch(errOrSkip => {
                self.$app.preloader.hide();
                if (errOrSkip == 'CLOSE') {
                  return $this.rootServices.apiService.putPromise('/collaborator/pages/' + self.pageInfo?.Code, { skipAuth: true, selfRegisterPublisherVerifyOpt: true, refId: self.$route.context['refId'] }, [{
                    Code: self.pageInfo?.Code,
                    Subscriber: {
                      ...self.register,
                      RefId: self.$route.context['refId'],
                      Otp: '1234',//Skip OTP Verified
                    },
                  }]).then(async (rs_1) => {
                    self.$app.preloader.hide();
                    $this.commonService.showInfo('Đăng ký thành côngs !');

                    self.$setState({ processing: false });
                    $this.commonService.showInfo('Chúc mừng bạn đã trở thành CTV Bán hàng của ' + self.pageInfo?.Name + ' !');
                    self.register.Code = rs[0].Code;
                    self.$setState({
                      register: self.register,
                    });

                    return true;
                  }).catch(err_3 => {
                    self.$app.preloader.hide();
                    $this.commonService.showError(err_3, { position: 'bottom' });
                    tryTime++;
                    if (tryTime <= 3) {
                      // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                      return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                    }
                    tryTime = 1;
                    return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                  });
                } else {
                  $this.commonService.showError(errOrSkip);
                  self.$setState({ notvalidated: false, regnotvalidated: false });
                }
              });


            }).catch(err => {
              $this.commonService.showError(err);
              self.$setState({ notvalidated: false, regnotvalidated: false });
            });
          },
          registerPublisher() {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.confirm(`Bạn có muốn trở thành CTV bán hàng của ${self.pageInfo?.Name}`, 'Đăng ký CTV bán hàng', () => {
              $this.rootServices.apiService.putPromise('/collaborator/pages/' + self.pageInfo?.Code, { registerPublisher: true }, [{
                Code: self.pageInfo?.Code
              }]).then(rs => {
                $this.commonService.showInfo('Chúc mừng bạn đã trở thành CTV bán hàng của ' + self.pageInfo?.Name + ' !');
              }).catch(err => {
                $this.commonService.showError(err);
              });
            });
          },
          subscribe(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            let pageId = self.$route.params?.id;
            const pagetag = self.$route.context['pageTag'];
            const refUserId = self.$route.context['refId'];
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            try {
              if (pagetag) {
                self.pageInfo = self.pageInfo || await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages', {
                  eq_TagName: pagetag,
                  ...(!$this.rootServices.apiService.token ? { skipAuth: true } : {}),
                  includeOrganization: true,
                  includeRefUserInfo: true,
                  includeRefInfo: true,
                  includeBusinessProducts: true,
                  refUserId: refUserId,
                }).then(rs => rs[0]);
              } else {
                self.pageInfo = self.pageInfo || await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId, {
                  ...(!$this.rootServices.apiService.token ? { skipAuth: true } : {}),
                  includeOrganization: true,
                  includeRefUserInfo: true,
                  includeRefInfo: true,
                  refUserId: refUserId,
                  includeBusinessProducts: true,
                }).then(rs => rs[0]);
              }

              pageId = self.pageInfo?.Code || pageId;

              const productList = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/products', {
                includeCategories: true,
                includeGroups: true,
                // filterByLevelAndBadge: true,
                includeSubscribed: true,
                sort_Id: true,
                page: pageId,
                search: self?.searchBar?.query && encodeURIComponent(self.searchBar.query) || '',
                includeFeaturePicture: true,
                includeUnit: true,
                offset: 0,
                limit: 10,
                skipAuth: true,
                getPublicProducts: true,
                productOfPage: true,
                onlyBusinessProducts: true,
                ...self.filter,
              }).then(products => {
                // self.$$(page.el).find('.page-content').scrollTo(0, 0);
                return products.map(t => $this.preapreProduct(t));
              });

              console.log(productList);

              // console.log(self.searchBar);

              // const products = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/products', {
              //   includeCategories: true,
              //   includeGroups: true,
              //   filterByLevelAndBadge: true,
              //   sort_Id: true,
              //   page: pageId,
              //   search: self?.searchBar?.query && encodeURIComponent(self.searchBar.query) || '',
              //   includeFeaturePicture: true,
              //   includeUnit: true,
              //   offset: 0,
              //   limit: 40,
              //   ...self.filter,
              // }).then(products => {
              //   // self.$$(page.el).find('.page-content').scrollTo(0, 0);
              //   return products.map(t => $this.preapreProduct(t));
              // });

              self.$setState({
                pageId: pageId,
                // productList: products,
                // infiniteLoadLast: products.length === 0,
                pageInfo: self.pageInfo,
                // lastUpdateMoment: moment(Date.now()).fromNow(),
                productList: productList,
                endOfProductList: productList.length < 10,
                beginOfProductList: true,
              });
              self.infiniteOffset = 0;

            } catch (err) {
              console.error(err);
            }

            if (done) done();
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          tabInit(e, page: F7Page) {
            console.log('tab init');
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            self.$setState({ pageId: pageId, pageInfo: $this.parentComponent?.currentState?.instance?.pageInfo });
            // Loading flag
            let allowInfinite = true;

            $this.parentComponent.currentState.instance.$setState({hideTabBar: true});

            // self.refresh();
            // self.searchBar = self.$app.searchbar.create({
            //   el: '.product-list-searchbar',
            //   backdrop: true,
            //   backdropEl: '.product-list-backdrop',
            //   searchContainer: '.list',
            //   searchIn: '.item-text',
            //   customSearch: true,
            //   on: {
            //     search(sb, query, previousQuery) {
            //       $this.commonService.takeUntil('product-search', 600).then(() => {
            //         console.log(query, previousQuery);
            //         $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
            //           search: encodeURIComponent(query),
            //           priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
            //           includeFeaturePicture: true,
            //           limit: 10,
            //           offset: 0,
            //           includeCategories: true,
            //           ...self.filter,
            //         }).then(products => {
            //           self.$$(page.el).find('.page-content').scrollTo(0, 0);
            //           self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
            //           self.infiniteOffset = 0;
            //         }).catch(err => {
            //           console.error(err);
            //           $this.commonService.showError(err);
            //         });
            //       });
            //     }
            //   }
            // });

            // self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
            //   // Exit, if loading in progress
            //   if (!allowInfinite) return;

            //   // Set loading flag
            //   allowInfinite = false;
            //   // Last loaded index
            //   var lastItemIndex = self.$$('.list li').length;

            //   // Emulate 1s loading
            //   $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
            //     search: self.searchBar.query,
            //     sort_LastUpdate: 'desc',
            //     limit: 10,
            //     offset: self.infiniteOffset,
            //     // sort_LastUpdate: 'desc',
            //     priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
            //     includeFeaturePicture: true,
            //     includeCategories: true,
            //     ...self.filter,
            //   }).then(products => {
            //     products.map(t => {
            //       $this.preapreProduct(t);
            //       return t;
            //     });
            //     self.$setState({ productList: self.productList.concat(products), infiniteLoadLast: products.length === 0 });
            //     self.infiniteOffset += 10;
            //     lastItemIndex = self.$$('.list li').length;
            //     allowInfinite = true;

            //     // self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     $this.commonService.showError(err);
            //   });
            // });


            // self.calendarDateTime = self.$f7.calendar.create({
            //   // openIn: 'customModal',
            //   inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
            //   timePicker: true,
            //   dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
            //   // header: true,
            //   footer: true,
            // });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, pageId);


          },
          tabMounted(e, page) {
            console.log('tab before mount');
          },
          tabBeforeRemove(e, page) {
            console.log('[tab event] before remove', page?.route?.url);
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            $this.onComponentRemove({ instance: self }, pageId);
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
